import React from 'react';

import { useTheme } from '@mui/material/styles';

import Link from 'next/link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Main from 'layouts/Main';

const ErrorPage = ({
  statusCode = 404,
  title = 'Something went wrong!',
  message = '',
  buttonText = 'Back home',
  buttonUrl = '/',
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main title="Error | Rejigg" hideActions>
      <Box
        bgcolor={theme.palette.alternate.main}
        minHeight={'calc(100vh - 200px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
        paddingTop={0}
      >
        <Container>
          <Grid container>
            <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} md={6}>
              <Box>
                <Typography variant="h2" component="h2" align={isMd ? 'left' : 'center'}>
                  {title}
                  <br />
                  {message}
                </Typography>
                <Box marginTop={4} display={'flex'} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                  <Button component={Link} variant="contained" color="primary" size="large" href={buttonUrl}>
                    {buttonText}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item container justifyContent={'center'} xs={12} md={6}>
              <Box height={1} width={1} maxWidth={500}>
                <Box
                  component={'img'}
                  src={'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration6.svg'}
                  width={1}
                  height={1}
                  sx={{
                    filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

ErrorPage.getInitialProps = async (context) => {
  const { res } = context;

  if (res) {
    res.statusCode = 404;
  }

  return {
    statusCode: 404,
  };
};

export default ErrorPage;
